<template>
  <v-window v-model="step" class="elevation-1">
    <!-- first step -->
    <v-window-item :value="1">
      <v-card class="pa-6" color="foreground">
        <v-card-title class="px-0 pt-0">
          <v-btn class="rounded-lg mr-3" icon @click="navigation('/main')">
            <v-icon> mdi-arrow-left </v-icon>
          </v-btn>

          Dados Pessoais
        </v-card-title>

        <!-- personal data -->
        <PersonalData />

        <h1 class="mb-4 text-h6">
          Selecione o banco para qual deseja efetuar a transferência
        </h1>

        <!-- awaiting -->
        <v-list-item v-if="awaitingMethods.includes('TRANSFER')" class="accent">
          <v-list-item-title class="text-center secondary_text--text">
            Transação pendente,
            <a @click="continueTransaction()">clique para continuar</a>
          </v-list-item-title>
        </v-list-item>

        <!-- form -->
        <v-form
          v-else-if="!lockedMethods['TRANSFER']"
          v-model="valid"
          ref="form"
          autocomplete="off"
        >
          <v-list class="px-0 transparent mb-6" nav>
            <v-list-item-group v-model="form.bank" mandatory>
              <template v-for="(item, index) in jwtDecoded.banks">
                <v-list-item :key="index" class="accent">
                  <!-- bank logo -->
                  <v-list-item-avatar color="background" class="ml-1 mr-3">
                    <v-img :src="item.icon" />
                  </v-list-item-avatar>

                  <v-list-item-content class="pr-3">
                    <!-- bank name -->
                    <v-list-item-title>
                      {{ item.bankName }}
                    </v-list-item-title>
                  </v-list-item-content>

                  <!-- active icon -->
                  <v-list-item-action>
                    <v-icon
                      :color="index == form.bank ? 'secondary' : 'grey'"
                      size="16"
                    >
                      {{
                        index == form.bank ? "mdi-circle" : "mdi-circle-outline"
                      }}
                    </v-icon>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>

          <v-btn color="primary" block large @click="submit()">
            Continuar Pagamento
          </v-btn>
        </v-form>

        <!-- unavaiable -->
        <v-list-item v-else class="accent">
          <v-list-item-title class="text-center secondary_text--text">
            Serviço indisponível para transferência TED/DOC
          </v-list-item-title>
        </v-list-item>

        <loader-hover v-if="loading" />
      </v-card>
    </v-window-item>

    <!-- second step -->
    <v-window-item :value="2">
      <v-card class="pa-6" color="foreground">
        <v-card-title class="px-0 pt-0">
          <v-btn
            v-if="!transactionPerformed"
            class="rounded-lg mr-3"
            icon
            @click="navigation(`/checkout`)"
          >
            <v-icon> mdi-arrow-left </v-icon>
          </v-btn>
          
          Transferência Bancária
        </v-card-title>

        <v-card-subtitle class="text-body-1 px-0">
          Realize a
          <span class="secondary--text">transferência</span>
          para a conta bancária a seguir, fique atento ao
          <span class="secondary--text">prazo</span> de compensação.
        </v-card-subtitle>

        <!-- transfer -->
        <section v-if="transaction.bank" class="mt-6">
          <v-list class="accent px-0 mb-3 rounded-lg">
            <v-list-item>
              <!-- bank logo -->
              <v-list-item-avatar
                color="background"
                class="ml-0 mr-3"
                size="32"
              >
                <v-img :src="transaction.bank.icon" />
              </v-list-item-avatar>

              <v-list-item-content class="pr-3">
                <!-- bank name -->
                <v-list-item-title>
                  {{ transaction.bank.bankName }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- account -->
            <v-list-item>
              <v-list-item-action-text class="text-body-1">
                Conta
              </v-list-item-action-text>

              <v-list-item-title class="text-right">
                {{ transaction.bank.accountNumber }}-{{
                  transaction.bank.accountDigit
                }}
              </v-list-item-title>
            </v-list-item>

            <!-- agency -->
            <v-list-item>
              <v-list-item-action-text class="text-body-1">
                Agência
              </v-list-item-action-text>

              <v-list-item-title class="text-right">
                {{ transaction.bank.agency }}-{{ transaction.bank.agencyDigit }}
              </v-list-item-title>
            </v-list-item>

            <!-- operação -->
            <v-list-item v-if="transaction.bank.operation !== 0">
              <v-list-item-action-text class="text-body-1">
                Operação
              </v-list-item-action-text>

              <v-list-item-title class="text-right">
                {{ transaction.bank.operation }}
              </v-list-item-title>
            </v-list-item>

            <!-- cod -->
            <v-list-item>
              <v-list-item-action-text class="text-body-1">
                Código
              </v-list-item-action-text>

              <v-list-item-title class="text-right">
                {{ transaction.bank.bankCode }}
              </v-list-item-title>
            </v-list-item>

            <!-- cnpj -->
            <v-list-item>
              <v-list-item-action-text class="text-body-1">
                CNPJ
              </v-list-item-action-text>

              <v-list-item-title class="text-right">
                {{ jwtDecoded.transaction.cnpj }}
              </v-list-item-title>
            </v-list-item>
          </v-list>

          <!-- transfer value -->
          <v-card class="mb-8 rounded-lg" color="accent" flat>
            <v-row class="py-4 px-4 align-center" no-gutters>
              <span class="text-h6 font-weight-bold">
                Transfira exatamente
              </span>

              <v-spacer />

              <span class="text-h5 primary--text">
                R$ {{ transaction.transferValue }}
              </span>
            </v-row>
          </v-card>

          <!-- receipt -->
          <v-card class="rounded-lg mb-6 px-4 pt-4" color="accent" flat>
            <p class="primary_text--text text-body-1">
              Para
              <span class="secondary--text">agilizar</span> o processo do seu
              pagamento, anexe o
              <span class="secondary--text">comprovante</span>.
            </p>

            <v-file-input
              v-model="file"
              accept="image/png, image/jpeg, image/bmp, image/jpg, .pdf"
              placeholder="Anexar comprovante de pagamento"
              outlined
            />
          </v-card>

          <v-btn class="mb-6" color="primary" large block @click="finishHim()">
            Já fiz o pagamento <v-icon right>mdi-arrow-right</v-icon>
          </v-btn>

          <div v-if="jwtDecoded.transaction.redirectURL" class="text-center">
            <a :href="jwtDecoded.transaction.redirectURL" class="text-button">
              Retornar para o site
            </a>
          </div>
        </section>

        <section v-else>
          <p>
            O banco solicitado não está disponível no momento, teve novamente
            mais tarde
          </p>

          <v-btn block @click="navigation('/checkout')">
            Retornar para o inicio
          </v-btn>
        </section>
      </v-card>
    </v-window-item>

    <loader-hover v-if="loading" />
  </v-window>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { transfer, sendReceipt } from "@/services/payment";
import { getTransaction } from "@/services/checkout";
import { mask } from "vue-the-mask";
import { displayAlert } from "@/utils";
import PersonalData from "@/components/checkout/PersonalData";

export default {
  data() {
    return {
      loading: false,
      valid: false,
      validReceipt: false,
      step: 1,
      form: {
        bank: 0,
      },
      transaction: {},
      file: null,
    };
  },

  directives: { mask },

  components: { PersonalData },

  beforeMount() {
    // prevent access this route with transaction performed
    if (this.transactionPerformed && this.jwtDecoded) {
      const method = this.jwtDecoded.transaction.method;

      if (method != "TRANSFER") {
        return this.$router.push({
          path: `/checkout/payment/${method.toLowerCase()}`,
        });
      }
    }

    if (this.current) this.handleCurrent();
  },

  computed: {
    ...mapState([
      "jwtDecoded",
      "current",
      "transactionPerformed",
      "awaitingMethods",
      "lockedMethods",
    ]),

    rule() {
      return [(v) => !!v || "Campo Obrigatório"];
    },
  },

  methods: {
    ...mapActions(["setCurrent", "setTransactionPerformed"]),

    // submit transfer
    async submit() {
      try {
        this.loading = true;

        const payload = {
          bankAccount: this.jwtDecoded.banks[this.form.bank].id,
          transactionId: this.jwtDecoded.transaction.ref,
        };

        await transfer(payload).then((res) => {
          this.transaction = {
            id: res.body.id,
            transactionId: res.body.transactionId,
            transferValue: res.body.transferValue,
            bank: this.jwtDecoded.banks[this.form.bank],
          };

          this.setTransactionPerformed(true);

          // get current transaction to commit
          this.getCurrentTransaction();

          this.step = 2;
        });
      } catch (err) {
        this.displayAlert(err.data.message, 1);
      } finally {
        this.loading = false;
      }
    },

    // send receipt to finish
    async finishHim() {
      if (!this.file) {
        // navigation to review
        return this.navigation(
          `/checkout/summary/${this.transaction.transactionId}`
        );
      }

      try {
        this.loading = true;

        const formData = new FormData();
        formData.append("file", this.file);

        const headers = {
          ref: this.transaction.transactionId,
        };

        await sendReceipt(formData, headers).then((res) => {
          this.displayAlert("Comprovante enviado com sucesso");

          // navigation to review
          this.navigation(
            `/checkout/summary/${this.transaction.transactionId}`
          );
        });
      } catch (err) {
        this.displayAlert(err.data.message, 1);
      } finally {
        this.loading = false;
      }
    },

    // get and commit current transaction
    async getCurrentTransaction() {
      try {
        const payload = {
          ref: this.jwtDecoded.transaction.ref,
          document: this.jwtDecoded.customer.document,
        };

        await getTransaction(payload).then((res) => {
          this.setCurrent(res.body); // commit on storage
        });
      } catch (err) {
        console.log(err);
      }
    },

    // handle current transaction
    handleCurrent() {
      this.transaction = {
        id: this.current.data.id,
        transactionId: this.current.data.transactionId,
        transferValue: this.current.data.transferValue,
        bank: this.jwtDecoded.banks.find(
          (e) => e.id === this.current.data.bankAccount
        ),
      };

      this.step = 2;
    },

    // continue current transactions
    continueTransaction() {
      // continue transaction
      const id = this.jwtDecoded.awaitingTransactions.find(
        (e) => e.paymentMethod === "TRANSFER"
      ).id;

      this.$root.$emit("continue-transaction", id);
    },

    // router push
    navigation(path) {
      this.$router.push({ path: path });
    },

    displayAlert,
  },
};
</script>

<style></style>
